/* Home.css */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.landing {
    margin: 0;
    position: relative;
    background-image: url('../../public/assets/img/fond.jpg');
    background-size: cover;
    background-position: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: left;
    transform: scaleX(-1);
    background-attachment: fixed;

&:before {
        content: "";
        background-color: rgba(0, 0, 0, 0.3);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: white;
        padding: 2rem;
    }

    &__title {
        font-size: 3rem;
        margin-bottom: 0.5rem;
    }

    &__subtitle {
        font-size: 2rem;
    }

}

.landing-card {
    margin-right: 7%;
    transform: scaleX(-1);

}

.landing h1 {
    color: #fff;
    font-size: 4em;
}

.about-us {
    background-image: url('../assets/img/fond_body.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -5%;

    padding: 100px;
}

.our_activity {
    padding-top: -10%;
}

/* animation for title */
.title-landing {
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.fade-in {
    opacity: 1;
}

.logo-container {
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    transform: translateX(100%);
}

.fade-in-logo {
    opacity: 1;
    transform: translateX(0%);
    animation: slideIn 1s ease-in-out forwards;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}
