.client-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 600px;
    /* Ajustez la largeur selon vos besoins */
}

.client-logo {
    max-width: 100px;
    /* Ajustez la taille des logos selon vos besoins */
    height: auto;
    margin: 0 10px;
    /* Ajustez l'espacement entre les logos selon vos besoins */
}

.orange-logo {
    width: 50%;
    /* Ajustez la taille selon vos besoins */
}

.nos-clients h2 {
    font-size: 2rem;
}